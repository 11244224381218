import gql from "graphql-tag";

export default {
  Queries: {
    user_by_token: gql`
      query user_by_token($token: String!) {
        user_by_token(token: $token) {
          user_id
          user_name
          user_email
          user_firstname
          user_lastname
          admin
          client {
            client_id
            client_name
          }
        }
      }
    `,
    devices: gql`
      query devices(
        $client_id: Int
        $limit: Int
        $offset: Int
        $search: String
      ) {
        devices(
          client_id: $client_id
          limit: $limit
          offset: $offset
          search: $search
        ) {
          device_id
          device_name
          device_serial
        }
      }
    `,
  },
  Mutations: {
    forgot_password: gql`
      mutation forgot_password($email: String!) {
        forgot_password(email: $email)
      }
    `,
  },
};
