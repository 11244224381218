import React, { useEffect, useState } from "react";
import { Container, Loader } from "rsuite";
import Cookie from "js-cookie";
import "./App.css";
import "leaflet/dist/leaflet.css";

import config from "./config";

import { useApp } from "./AppStore";

import Header from "./layout/header";
import Login from "./layout/login";
import Main from "./layout/main";
import Footer from "./layout/footer";

// GQL
import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";

const token = Cookie.get(config.session_cookie_name);

function App(props) {
  const [{ loggedIn }, actions] = useApp();
  const apolloClient = useApolloClient();
  const [appLoaded, setAppLoaded] = useState(false);

  //console.log({ props });

  useEffect(() => {
    //console.log({ token });
    //console.log(window.location.pathname);
    if (window.location.pathname === "/login") {
      Cookie.set(config.session_cookie_name, null);
      setAppLoaded(true);
      //actions.logout();
      //window.location = "/logout";
    } else {
      if (!token || token === "") {
        Cookie.set(config.session_cookie_name, null);
        window.location = "/logout";
      } else {
        apolloClient
          .query({
            query: gql`
              query is_valid_token($token: String!) {
                is_valid_token(token: $token)
              }
            `,
            variables: {
              token,
            },
          })
          .then((is_valid_token) => {
            //console.log({ is_valid_token });
            if (
              !is_valid_token ||
              !is_valid_token.data ||
              !is_valid_token.data.is_valid_token
            ) {
              Cookie.set(config.session_cookie_name, null);
              actions.logout();
              setAppLoaded(true);
              window.location = "/login";
            } else {
              setAppLoaded(true);
            }
          });
      }
    }
  }, [apolloClient, actions]);

  return (
    <div className="App">
      {!appLoaded ? (
        <Container
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <div>
            <img
              src="/images/mc3-logo.png"
              width="200px"
              alt="logo"
              style={{ alignSelf: "center" }}
            />
          </div>
          <div>
            <Loader
              //center
              speed="fast"
              size="md"
              content="Loading..."
              vertical
            />
          </div>
        </Container>
      ) : !loggedIn ? (
        <Login />
      ) : (
        <Main
          page={
            props &&
            props.match &&
            props.match.params &&
            props.match.params.page
              ? props.match.params.page
              : "devices"
          }
          id={
            props && props.match && props.match.params && props.match.params.id
              ? props.match.params.id
              : 0
          }
          {...props}
        />
      )}
      <Footer />
    </div>
  );
}

export default App;
