import React from "react";
import { Container } from "rsuite";

export default (props) => {
  return (
    <Container
      style={{
        paddingTop: "10px",
        paddingLeft: "10%",
        paddingRight: "10%",
        width: "100%",
        textAlign: "center",
      }}
    >
      <small>MC3 Solutions LLC</small>
    </Container>
  );
};
