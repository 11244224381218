import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import JSONPretty from "react-json-pretty";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  InputPicker,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  TreePicker,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

const JSONPrettyMon = require("react-json-pretty/dist/monikai");

export default (props) => {
  const { data, loading } = useQuery(GQL.Queries.client_api_token);

  //console.log({ data });

  return (
    <Container
      style={{
        padding: "25px",
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>API</b> Documentation
            </div>
          </Col>
        </Row>
      </Grid>
      <Panel
        className="panel"
        style={{
          width: "100%",
          marginTop: "15px",
          height: window.innerHeight - 130,
          overflowY: "scroll",
          padding: 0,
        }}
      >
        <Panel header={<strong>Overview</strong>}>
          The Conduit REST API allows developers to query our system using a
          simple URI syntax. All requests return JSON. The REST API allows you
          to interact with various part of the Conduit system allowing for
          integration with your systems.
        </Panel>
        <Panel header={<strong>Implementation</strong>}>
          <p>
            <code className="codebox">
              API Base URL:
              https://conduit-api.mc3solutions.com/&#123;api_call&#125;
              <br />
              Headers: &#123; api_token:{" "}
              {!loading && data && data.client_api_token
                ? data.client_api_token
                : "..."}{" "}
              &#125;
              <br />
              Body: &#123; data &#125;
            </code>
          </p>
          <p>
            Each request must include an API token. Your api token is listed
            above. To include an api token in your request add an 'api_token'
            field to your HTTP Request Header with the value being the token
            listed above.
          </p>
        </Panel>
        <Panel header={<strong>Responses</strong>}>
          <Panel header="Status">
            All responses will include a status:
            <Table
              autoHeight={true}
              data={[
                {
                  statusCode: 200,
                  status: "OK",
                  statusDescription:
                    "Indicates that everything is ok and data has been returned, created, updated, or removed.",
                },
                {
                  statusCode: 400,
                  status: "Bad Request",
                  statusDescription:
                    "The request was denied due to malformed syntax, name/value pair mismatch, or input value(s) failed validation.",
                },
                {
                  statusCode: 401,
                  status: "Unauthorzied",
                  statusDescription:
                    "The request was made from an entity not associated with the API token or the API token was not sent.",
                },
                {
                  statusCode: 403,
                  status: "Forbidden",
                  statusDescription:
                    "Either the end user does not permission to access that resource or a temporary/permanent API ban has incurred by abuse or insufficient privileges.",
                },
                {
                  statusCode: 500,
                  status: "Server Error",
                  statusDescription:
                    "The server encountered an unexpected condition which prevented it from fulfilling the request.",
                },
              ]}
            >
              <Table.Column>
                <Table.HeaderCell>Status Code</Table.HeaderCell>
                <Table.Cell dataKey="statusCode" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.Cell dataKey="status" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.Cell dataKey="statusDescription" />
              </Table.Column>
            </Table>
          </Panel>
          <Panel header="Message">
            All responses will include a status_message:
            <Table
              autoHeight
              data={[
                {
                  code: "success",
                  description: "Request was successful.",
                },
                {
                  code: "success-empty set",
                  description:
                    "Request was successful but no data has been returned.",
                },
                {
                  code: "validation errors",
                  description: "Errors were found with the request params.",
                },
                {
                  code: "unauthorized",
                  description: "User is not authorized to access the api call.",
                },
                {
                  code: "forbidden",
                  description:
                    "User does not have access to create/update/remove this resource.",
                },
              ]}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.Cell dataKey="code" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>
          </Panel>
          <Panel header="Data">
            All responses will include either a data or errors array:
            <Table
              autoHeight
              data={[
                { name: "data", description: "An array of the returned data." },
                {
                  name: "errors",
                  description:
                    "An array of all errors found with the request submission.",
                },
              ]}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>
          </Panel>
        </Panel>
        <Panel header={<strong>API Calls</strong>}>
          <Panel header="Get Client Devices">
            <Table
              autoHeight
              data={[
                {
                  name: "URI",
                  description:
                    "https://conduit-api.mc3solutions.com/devices/all",
                },
                {
                  name: "Params",
                  description: "None",
                },
                {
                  name: "Return",
                  description:
                    "This method returns an array of all active client devices",
                },
              ]}
              showHeader={false}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell />
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell />
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>

            <JSONPretty
              data={`{"status":"200","status_message":"success","data":[{"device_id":"1","devicetype_id":"1","queue_id":"2","device_name":"Device 1","device_serial":"1234567890","devicetype_name":"Devicetype 1","queue_name":"Queue 1"},{"device_id":"3","client_id":"1","devicetype_id":"1","queue_id":"2","device_name":"Device 2","device_serial":"1234567891","devicetype_name":"Devicetype 2","queue_name":"Queue 2"},{"device_id":"2","client_id":"1","devicetype_id":"2","queue_id":"2","device_name":"Device 3","device_serial":"1234567893","devicetype_name":"Devicetype 3","queue_name":"Queue 3"}]}`}
              theme={JSONPrettyMon}
            ></JSONPretty>
          </Panel>
          <Panel header="Get Client Device By Serial">
            <Table
              autoHeight
              data={[
                {
                  name: "URI",
                  description:
                    "https://conduit-api.mc3solutions.com/devices/get-device-by-serial",
                },
                {
                  name: "Params",
                  description: "device_serial",
                },
                {
                  name: "Return",
                  description:
                    "This method returns an array of the requested device.",
                },
              ]}
              showHeader={false}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell />
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell />
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>
            <JSONPretty
              data={`{"status":"200","status_message":"success","data":{"device_id":"1","devicetype_id":"1","queue_id":"2","device_name":"Device 1","device_serial":"1234567890","devicetype_name":"Devicetype 1","queue_name":"Queue 1"}}`}
              theme={JSONPrettyMon}
            ></JSONPretty>
          </Panel>
          <Panel header="Get Client Queues">
            <Table
              autoHeight
              data={[
                {
                  name: "URI",
                  description:
                    "https://conduit-api.mc3solutions.com/queues/all",
                },
                {
                  name: "Params",
                  description: "None",
                },
                {
                  name: "Return",
                  description:
                    "This method returns an array of all active client queues.",
                },
              ]}
              showHeader={false}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell />
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell />
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>

            <JSONPretty
              data={`{"status":"200","status_message":"success","data":[{"queue_id":"1","queue_name":"Queue 1"},{"queue_id":"2","queue_name":"Queue 2"},{"queue_id":"3","queue_name":"Queue 3"}]}`}
              theme={JSONPrettyMon}
            ></JSONPretty>
          </Panel>
          <Panel header="Get Devicetypes">
            <Table
              autoHeight
              data={[
                {
                  name: "URI",
                  description:
                    "https://conduit-api.mc3solutions.com/devicetypes/all",
                },
                {
                  name: "Params",
                  description: "None",
                },
                {
                  name: "Return",
                  description:
                    "This method returns an array of all active client devices.",
                },
              ]}
              showHeader={false}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell />
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell />
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>

            <JSONPretty
              data={`{"status":"200","status_message":"success","data":[{"devicetype_id":"1","devicetype_name":"Devicetype 1"},{"devicetype_id":"2","devicetype_name":"Devicetype 2"},{"devicetype_id":"3","devicetype_name":"Devicetype 3"}]}`}
              theme={JSONPrettyMon}
            ></JSONPretty>
          </Panel>
          <Panel header="Add OTA">
            <Table
              autoHeight
              data={[
                {
                  name: "URI",
                  description:
                    "https://conduit-api.mc3solutions.com/devices/add-ota",
                },
                {
                  name: "Params",
                  description: "device_id, command",
                },
                {
                  name: "Return",
                  description:
                    "This method stores an OTA command in the database to be sent.",
                },
              ]}
              showHeader={false}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell />
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column flexGrow={6}>
                <Table.HeaderCell />
                <Table.Cell dataKey="description" />
              </Table.Column>
            </Table>

            <JSONPretty
              data={`{"status":"200","status_message":"success-empty set","data":[]}`}
              theme={JSONPrettyMon}
            ></JSONPretty>
          </Panel>
        </Panel>
      </Panel>
    </Container>
  );
};
