import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

export default (props) => {
  const [showAddClientModal, setShowAddClientModal] = useState(false);

  const limit = parseInt((window.innerHeight - 500) / 45);

  // Add Device
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { data, loading, refetch } = useQuery(GQL.Queries.clients);
  const {
    data: count,
    loading: loading_count,
    refetch: refetch_count,
  } = useQuery(GQL.Queries.client_count);

  console.log({ data });

  return (
    <Container
      style={{
        padding: "25px",
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>Clients</b> Statistics & Dashboard
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid style={{ width: "100%", margin: 0, marginTop: "15px", padding: 0 }}>
        <Row>
          <Col xs={6} style={{ padding: 5, paddingTop: 0 }}>
            <Panel
              header={
                <span style={{ color: "#8e9fb1" }}>
                  <small>Billing</small>
                </span>
              }
              className="panel"
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    color: "#589a5d",
                    fontSize: 30,
                    fontWeight: "bold",
                    position: "relative",
                    top: "-15px",
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    data.clients.filter((client) => client.billing_current)
                      .length
                  )}
                </div>
                <div style={{ color: "#8e9fb1" }}>
                  <big>Current</big>
                </div>
              </div>
            </Panel>
          </Col>
          <Col xs={6} style={{ padding: 5, paddingTop: 0 }}>
            <Panel
              header={
                <span style={{ color: "#8e9fb1" }}>
                  <small>Billing</small>
                </span>
              }
              className="panel"
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    color: "#CB5329",
                    fontSize: 30,
                    fontWeight: "bold",
                    position: "relative",
                    top: "-15px",
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    data.clients.filter((client) => !client.billing_current)
                      .length
                  )}
                </div>
                <div style={{ color: "#8e9fb1" }}>
                  <big>Outstanding</big>
                </div>
              </div>
            </Panel>
          </Col>
          <Col xs={6} style={{ padding: 5, paddingTop: 0 }}>
            <Panel
              header={
                <span style={{ color: "#8e9fb1" }}>
                  <small>This Month</small>
                </span>
              }
              className="panel"
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    color: "#746198",
                    fontSize: 30,
                    fontWeight: "bold",
                    position: "relative",
                    top: "-15px",
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    data.clients.filter(
                      (client) =>
                        moment.unix(client.createdAt / 1000).valueOf() >
                        moment.utc().startOf("month").valueOf()
                    ).length
                  )}
                </div>
                <div style={{ color: "#8e9fb1" }}>
                  <big>New Clients</big>
                </div>
              </div>
            </Panel>
          </Col>
          <Col xs={6} style={{ padding: 5, paddingTop: 0, paddingRight: 5 }}>
            <Panel
              header={
                <span style={{ color: "#8e9fb1" }}>
                  <small>Total</small>
                </span>
              }
              className="panel"
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    color: "#4781a7",
                    fontSize: 30,
                    fontWeight: "bold",
                    position: "relative",
                    top: "-15px",
                  }}
                >
                  {loading_count ? <Loader /> : count.client_count}
                </div>
                <div style={{ color: "#8e9fb1" }}>
                  <big>Clients</big>
                </div>
              </div>
            </Panel>
          </Col>
        </Row>
      </Grid>
      <Grid
        className="panel"
        style={{ width: "100%", marginTop: "5px", padding: "10px" }}
      >
        <Row style={{ padding: "10px" }}>
          <Col xs={12} style={{ textAlign: "left" }}>
            <span style={{ color: "#8e9fb1" }}>
              <Icon icon="list" style={{ margin: "5px" }} /> Summary
            </span>
          </Col>
          <Col xs={12} style={{ textAlign: "right" }}>
            <Button
              onClick={() => setShowAddClientModal(true)}
              className="button-shadow"
            >
              <Icon icon="plus" /> Add Client
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              data={loading ? [] : data.clients}
              style={{
                borderRadius: "5px",
                //boxShadow: "rgba(0, 0, 0, 0.1) 15px 0px 25px, rgba(0, 0, 0, 0.1) -15px 0px 25px",
                backgroundColor: "rgba(0,0,0,0.0)",
                //marginTop: "5px",
              }}
              //hover={false}
              //bordered
              //cellBordered
              autoHeight
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Name
                </Table.HeaderCell>
                <Table.Cell dataKey="client_name" />
              </Table.Column>
              <Table.Column flexGrow={2}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Address
                </Table.HeaderCell>
                <Table.Cell>
                  {(client) =>
                    client.client_address +
                    " " +
                    client.client_city +
                    ", " +
                    client.client_state +
                    ". " +
                    client.client_zip
                  }
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  E-Mail
                </Table.HeaderCell>
                <Table.Cell dataKey="client_email" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Phone
                </Table.HeaderCell>
                <Table.Cell dataKey="client_phone" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Reported Monthly
                </Table.HeaderCell>
                <Table.Cell dataKey="reported_monthly" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Reported All Time
                </Table.HeaderCell>
                <Table.Cell dataKey="reported_total" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Total Devices
                </Table.HeaderCell>
                <Table.Cell dataKey="total_devices" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Event Count
                </Table.HeaderCell>
                <Table.Cell dataKey="event_count" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Est. Invoice
                </Table.HeaderCell>
                <Table.Cell dataKey="est_invoice" />
              </Table.Column>
            </Table>
            {/**<Table.Pagination />*/}
          </Col>
        </Row>
      </Grid>
      <Modal
        show={showAddClientModal}
        onHide={() => setShowAddClientModal(false)}
        className="big-shadow"
      >
        <Modal.Header>
          <Modal.Title>Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="tag" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Client Name"
                  value={name}
                  onChange={setName}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="envelope" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Client E-Mail"
                  value={email}
                  onChange={setEmail}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="phone" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Client Phone"
                  value={phone}
                  onChange={setPhone}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="map-signs" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Address"
                  value={address}
                  onChange={setAddress}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="home" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="City"
                  value={city}
                  onChange={setCity}
                />
                <FormControl
                  placeholder="State"
                  value={state}
                  onChange={setState}
                />
                <FormControl
                  placeholder="Zip Code"
                  value={zip}
                  onChange={setZip}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            //onClick={() => setShowAddDeviceModal(false)}
            appearance="primary"
            color="green"
            className="button-shadow"
            size="lg"
          >
            <Icon icon="plus-circle" /> Add
          </Button>
          <Button
            onClick={() => setShowAddClientModal(false)}
            appearance="subtle"
            //className="button-shadow"
            size="lg"
          >
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
