import gql from "graphql-tag";

export default {
  Queries: {
    users: gql`
      query users($limit: Int, $offset: Int) {
        users(limit: $limit, offset: $offset) {
          user_id
          user_firstname
          user_lastname
          user_name
          user_email
        }
      }
    `,
    user_count: gql`
      query user_count {
        user_count
      }
    `,
  },
  Mutations: {},
};
