import gql from "graphql-tag";

export default {
  Queries: {
    queues: gql`
      query queues {
        queues {
          queue_id
          queue_name
          queue_token_name
          queue_token
          queue_type
          queue_url
        }
      }
    `,
  },
  Mutations: {},
};
