import gql from "graphql-tag";

export default {
  Queries: {
    client_api_token: gql`
      query client_api_token {
        client_api_token
      }
    `,
  },
  Mutations: {},
};
