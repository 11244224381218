import gql from "graphql-tag";

export default {
  Queries: {
    user_by_token: gql`
      query user_by_token($token: String!) {
        user_by_token(token: $token) {
          user_id
          user_name
          user_email
          user_firstname
          user_lastname
        }
      }
    `,
  },
  Mutations: {
    update_user: gql`
      mutation update_user(
        $user_id: Int!
        $user_name: String
        $user_firstname: String
        $user_lastname: String
        $user_email: String
        $user_password: String
      ) {
        update_user(
          user_id: $user_id
          user_name: $user_name
          user_firstname: $user_firstname
          user_lastname: $user_lastname
          user_email: $user_email
          user_password: $user_password
        )
      }
    `,
  },
};
