import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

export default (props) => {
  const [showAddQueueModal, setShowAddQueueModal] = useState(false);

  const limit = parseInt((window.innerHeight - 500) / 45);

  // Add Device
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { data, loading, refetch } = useQuery(GQL.Queries.queues);

  console.log({ data });

  return (
    <Container
      style={{
        padding: "25px",
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>Queues</b> Dashboard
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid
        className="panel"
        style={{ width: "100%", marginTop: "15px", padding: "10px" }}
      >
        <Row style={{ padding: "10px" }}>
          <Col xs={12} style={{ textAlign: "left" }}>
            <span style={{ color: "#8e9fb1" }}>
              <Icon icon="list" style={{ margin: "5px" }} /> Summary
            </span>
          </Col>
          <Col xs={12} style={{ textAlign: "right" }}>
            <Button
              onClick={() => setShowAddQueueModal(true)}
              className="button-shadow"
            >
              <Icon icon="plus" /> Add Queue
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              data={loading ? [] : data.queues}
              style={{
                borderRadius: "5px",
                //boxShadow: "rgba(0, 0, 0, 0.1) 15px 0px 25px, rgba(0, 0, 0, 0.1) -15px 0px 25px",
                backgroundColor: "rgba(0,0,0,0.0)",
                //marginTop: "5px",
              }}
              //hover={false}
              //bordered
              //cellBordered
              autoHeight
              loading={loading}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Name
                </Table.HeaderCell>
                <Table.Cell dataKey="queue_name" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Token Name
                </Table.HeaderCell>
                <Table.Cell dataKey="queue_token_name" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Token
                </Table.HeaderCell>
                <Table.Cell dataKey="queue_token" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Queue Type
                </Table.HeaderCell>
                <Table.Cell dataKey="queue_type" />
              </Table.Column>
              <Table.Column flexGrow={2}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Queue URL
                </Table.HeaderCell>
                <Table.Cell dataKey="queue_url" />
              </Table.Column>
            </Table>
            {/**<Table.Pagination />*/}
          </Col>
        </Row>
      </Grid>
      <Modal
        show={showAddQueueModal}
        onHide={() => setShowAddQueueModal(false)}
        className="big-shadow"
      >
        <Modal.Header>
          <Modal.Title>Add Queue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="tag" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Queue Name"
                  value={name}
                  onChange={setName}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            //onClick={() => setShowAddDeviceModal(false)}
            appearance="primary"
            color="green"
            className="button-shadow"
            size="lg"
          >
            <Icon icon="plus-circle" /> Add
          </Button>
          <Button
            onClick={() => setShowAddQueueModal(false)}
            appearance="subtle"
            //className="button-shadow"
            size="lg"
          >
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
