import React, { useState } from "react";
import { Container, Sidebar, Sidenav, Nav, Icon } from "rsuite";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/react-hooks";
import GQL from "./graphql";
import config from "../config";
import { useApp } from "../AppStore";

const token = Cookie.get(config.session_cookie_name);

export default (props) => {
  const { page } = props;
  const [{ selectedPage: globalSelectedPage, menuExpanded, user }, actions] =
    useApp();

  const selectedPage = page ? page : globalSelectedPage;
  const history = useHistory();
  const [pages, setPages] = useState([
    {
      icon: "map-marker",
      value: "devices",
      label: "Devices",
    },
    {
      icon: "user",
      value: "users",
      label: "Users",
    },
    {
      icon: "building-o",
      value: "clients",
      label: "Clients",
    },
  ]);
  const [sections, setSections] = useState([
    {
      name: "DEVICES",
      menuItems: [
        {
          icon: "map-marker",
          value: "devicesDashboard",
          label: "Dashboard",
        },
        {
          icon: "map-marker",
          value: "devicesReports",
          label: "Events",
        },
      ],
    },
  ]);
  const {
    data: user_data,
    loading: loading_user,
    refetch: refetch_user,
  } = useQuery(GQL.Queries.user_by_token, {
    variables: { token },
    onCompleted: (res) => {
      if (res && res.user_by_token && res.user_by_token.admin) {
        setPages([
          {
            icon: "map-marker",
            value: "devices",
            label: "Devices",
          },
          {
            icon: "building-o",
            value: "clients",
            label: "Clients",
          },
          {
            icon: "user",
            value: "users",
            label: "Users",
          },
          {
            icon: "list",
            value: "queues",
            label: "Queues",
          },
          {
            icon: "money",
            value: "billing",
            label: "Billing",
          },
          {
            icon: "cog",
            value: "api",
            label: "API Docs",
          },
        ]);
      } else {
        setPages([
          {
            icon: "map-marker",
            value: "devices",
            label: "Devices",
          },
          {
            icon: "user",
            value: "users",
            label: "Users",
          },
          {
            icon: "list",
            value: "queues",
            label: "Queues",
          },
          {
            icon: "money",
            value: "billing",
            label: "Billing",
          },
          {
            icon: "cog",
            value: "api",
            label: "API Docs",
          },
        ]);
      }
    },
  });

  //console.log({ user_data });

  return (
    <Sidebar
      style={{
        backgroundColor: "rgba(0,0,0,0.0)",
        //backdropFilter: "blur(3px)",
        //boxShadow:          "rgba(0, 0, 0, 0.3) 15px 0px 25px, rgba(0, 0, 0, 0.3) -15px 0px 25px",
        margin: "10px",
        marginLeft: "35px",
        borderRadius: "5px",
      }}
      //collapsible
      width={150}
    >
      <Sidenav
        style={{
          backgroundColor: "rgba(0,0,0,0.0)",
        }}
        expanded={menuExpanded}
      >
        <Sidenav.Header style={{ padding: 0, paddingBottom: 20 }}>
          <small>
            {loading_user
              ? "..."
              : user_data &&
                user_data.user_by_token &&
                user_data.user_by_token.client &&
                user_data.user_by_token.client.client_name
              ? user_data.user_by_token.client.client_name
              : "MC3 Solutions LLC"}
          </small>
        </Sidenav.Header>
        <Sidenav.Body>
          {/*
          {sections.map((section) => (
            <Container>
              <Container style={{ textAlign: "left" }}>
                <small>{section.name}</small>
              </Container>
              <Nav>
                {section.menuItems.map((page) => (
                  <Nav.Item
                    key={page.value}
                    eventKey={page.value}
                    onClick={() => {
                      history.push("/" + page.value);
                    }}
                    icon={
                      <Icon
                        icon={page.icon}
                        style={{
                          color: "rgba(255,255,255,0.25)",
                        }}
                      />
                    }
                    active={page.value === selectedPage}
                    style={{
                      textAlign: "left",
                      fontWeight:
                        page.value === selectedPage ? "bold" : "normal",
                      borderRadius: "5px",
                    }}
                  >
                    <span
                      style={{
                        color:
                          page.value === selectedPage
                            ? "rgba(255,255,255,0.96)"
                            : "rgba(255,255,255,0.7)",
                      }}
                    >
                      {page.label}
                    </span>
                  </Nav.Item>
                ))}
              </Nav>
            </Container>
          ))}
                    */}
          <Nav>
            {pages.map((page) => (
              <Nav.Item
                key={page.value}
                eventKey={page.value}
                onClick={() => {
                  history.push("/" + page.value);
                }}
                icon={
                  <Icon
                    icon={page.icon}
                    style={{
                      color:
                        page.value === selectedPage
                          ? "#0176BC"
                          : "rgba(255,255,255,0.2)",
                    }}
                  />
                }
                active={page.value === selectedPage}
                style={{
                  textAlign: "left",
                  fontWeight: page.value === selectedPage ? "bold" : "normal",
                  borderRadius: "5px",
                }}
              >
                <span
                  style={{
                    color:
                      page.value === selectedPage
                        ? "rgba(255,255,255,1)"
                        : "rgba(255,255,255,0.7)",
                  }}
                >
                  {page.label}
                </span>
              </Nav.Item>
            ))}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </Sidebar>
  );
};
