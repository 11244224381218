import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  HelpBlock,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

const DevicetypeCount = (props) => {
  const { devicetype_id } = props;
  const { data, loading, refetch } = useQuery(GQL.Queries.devicetype_count, {
    variables: { devicetype_id },
  });

  return (
    <div style={{ textAlign: "right" }}>
      {loading ? (
        <Loader />
      ) : data && data.devicetype_count ? (
        data.devicetype_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      ) : (
        "0"
      )}
    </div>
  );
};

const ReportedToday = (props) => {
  const { devicetype_id } = props;
  const { data, loading, refetch } = useQuery(
    GQL.Queries.reported_today_count,
    {
      variables: { devicetype_id },
    }
  );
  const history = useHistory();

  return (
    <div style={{ textAlign: "right" }}>
      {loading ? (
        <Loader />
      ) : data && data.reported_this_month_count ? (
        <span
          onClick={() =>
            history.push("/devices/reported_this_month/" + devicetype_id)
          }
          style={{ cursor: "pointer", color: "#217FBC", fontWeight: "bold" }}
        >
          {data.reported_this_month_count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ) : (
        "0"
      )}
    </div>
  );
};

const ReportedThisMonth = (props) => {
  const { devicetype_id } = props;
  const { data, loading, refetch } = useQuery(
    GQL.Queries.reported_this_month_count,
    {
      variables: { devicetype_id },
    }
  );
  const history = useHistory();

  return (
    <div style={{ textAlign: "right" }}>
      {loading ? (
        <Loader />
      ) : data && data.reported_this_month_count ? (
        <span
          onClick={() =>
            history.push("/devices/reported_this_month/" + devicetype_id)
          }
          style={{ cursor: "pointer", color: "#217FBC", fontWeight: "bold" }}
        >
          {data.reported_this_month_count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ) : (
        "0"
      )}
    </div>
  );
};

const ReportedAllTime = (props) => {
  const { devicetype_id } = props;
  const { data, loading, refetch } = useQuery(
    GQL.Queries.reported_all_time_count,
    {
      variables: { devicetype_id },
    }
  );
  const history = useHistory();

  return (
    <div style={{ textAlign: "right" }}>
      {loading ? (
        <Loader />
      ) : data && data.reported_all_time_count ? (
        <span
          onClick={() => history.push("/devices/reported/" + devicetype_id)}
          style={{ cursor: "pointer", color: "#217FBC", fontWeight: "bold" }}
        >
          {data.reported_all_time_count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ) : (
        "0"
      )}
    </div>
  );
};

const EventCount = (props) => {
  const { devicetype_id } = props;
  const { data, loading, refetch } = useQuery(GQL.Queries.monthly_event_count, {
    variables: { devicetype_id },
  });

  return (
    <div style={{ textAlign: "right" }}>
      {loading ? (
        <Loader />
      ) : data && data.monthly_event_count ? (
        data.monthly_event_count
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      ) : (
        "0"
      )}
    </div>
  );
};

export default (props) => {
  const limit = parseInt((window.innerHeight - 500) / 45);

  const history = useHistory();

  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [offset, setOffset] = useState(0);

  // Add Device
  const [deviceName, setDeviceName] = useState("");
  const [deviceSerial, setDeviceSerial] = useState("");

  // Import Devices
  const [showTemplate, setShowTemplate] = useState(false);

  // Shared
  const [devicetype_id, setDevicetype_id] = useState();
  const [queue_id, setQueue_id] = useState();

  // GQL
  const {
    data: monthly_event_count,
    loading: loading_monthly_event_count,
    refetch: refetch_monthly_event_count,
  } = useQuery(GQL.Queries.monthly_event_count);
  const {
    data: reported_today_count,
    loading: loading_reported_today_count,
    refetch: refetch_reported_today_count,
  } = useQuery(GQL.Queries.reported_today_count, {
    onCompleted: (data) => {
      //console.log(data);
    },
  });
  const {
    data: reported_this_week_count,
    loading: loading_reported_this_week_count,
    refetch: refetch_reported_this_week_count,
  } = useQuery(GQL.Queries.reported_this_week_count, {
    onCompleted: (data) => {
      //console.log({ reported_this_week_count: data });
    },
  });
  const {
    data: reported_this_month_count,
    loading: loading_reported_this_month_count,
    refetch: refetch_reported_this_month_count,
  } = useQuery(GQL.Queries.reported_this_month_count, {
    onCompleted: (data) => {
      //console.log(data);
    },
  });
  const {
    data: reported_all_time_count,
    loading: loading_reported_all_time_count,
    refetch: refetch_reported_all_time_count,
  } = useQuery(GQL.Queries.reported_all_time_count, {
    onCompleted: (data) => {
      //console.log({ reported_all_time_count: data });
    },
  });
  const {
    data: data_total,
    loading: loading_total,
    refetch: refetch_total,
  } = useQuery(GQL.Queries.device_count, {
    onCompleted: (data) => {
      //console.log({ data });
    },
  });
  const { data: devicetypes, loading: loading_devicetypes } = useQuery(
    GQL.Queries.devicetypes
  );
  const { data: queues, loading: loading_queues } = useQuery(
    GQL.Queries.queues
  );
  const { data, loading, refetch } = useQuery(GQL.Queries.devicetypes, {
    variables: {
      offset,
      limit,
      only_show_used_devicetypes: true,
    },
  });
  const [addDevice] = useMutation(GQL.Mutations.add_device);

  //console.log({ devicetypes });

  return (
    <>
      <Grid style={{ width: "100%", margin: 0, marginTop: "15px", padding: 0 }}>
        <Row>
          <Col xs={6} style={{ padding: 5, paddingTop: 0 }}>
            <Panel
              className="panel"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/devices/reported_today")}
            >
              <Grid style={{ width: "100%" }}>
                <Row>
                  <Col xs={8}>
                    <div style={{ width: "5rem", height: "5rem" }}>
                      <CircularProgressbar
                        value={
                          reported_today_count &&
                          reported_today_count.reported_today_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_today_count.reported_today_count /
                                  data_total.device_count) *
                                  100
                              )
                            : 0
                        }
                        maxValue={100}
                        text={
                          reported_today_count &&
                          reported_today_count.reported_today_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_today_count.reported_today_count /
                                  data_total.device_count) *
                                  100
                              ) + "%"
                            : ""
                        }
                        styles={buildStyles({
                          pathColor: "#589a5d",
                          textColor: "#589a5d",
                          trailColor: "rgba(0, 0, 0, 0.15)",
                        })}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={16}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div style={{ color: "#589a5d", fontSize: "2rem" }}>
                      {loading_reported_today_count || loading_total ? (
                        <Loader />
                      ) : (
                        reported_today_count.reported_today_count
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      )}
                    </div>
                    <div style={{ color: "#8e9fb1" }}>Reported Today</div>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Col>
          <Col xs={6} style={{ padding: 5, paddingTop: 0 }}>
            <Panel
              className="panel"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/devices/reported_this_week")}
            >
              <Grid style={{ width: "100%" }}>
                <Row>
                  <Col xs={8}>
                    <div style={{ width: "5rem", height: "5rem" }}>
                      <CircularProgressbar
                        value={
                          reported_this_week_count &&
                          reported_this_week_count.reported_this_week_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_this_week_count.reported_this_week_count /
                                  data_total.device_count) *
                                  100
                              )
                            : 0
                        }
                        maxValue={100}
                        text={
                          reported_this_week_count &&
                          reported_this_week_count.reported_this_week_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_this_week_count.reported_this_week_count /
                                  data_total.device_count) *
                                  100
                              ) + "%"
                            : ""
                        }
                        styles={buildStyles({
                          pathColor: "#b27d58",
                          textColor: "#b27d58",
                          trailColor: "rgba(0, 0, 0, 0.15)",
                        })}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={16}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div style={{ color: "#b27d58", fontSize: "2rem" }}>
                      {loading_reported_this_week_count || loading_total ? (
                        <Loader />
                      ) : (
                        reported_this_week_count.reported_this_week_count
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      )}
                    </div>
                    <div style={{ color: "#8e9fb1" }}>Reported This Week</div>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Col>
          <Col xs={6} style={{ padding: 5, paddingTop: 0 }}>
            <Panel
              className="panel"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/devices/reported_this_month")}
            >
              <Grid style={{ width: "100%" }}>
                <Row>
                  <Col xs={8}>
                    <div style={{ width: "5rem", height: "5rem" }}>
                      <CircularProgressbar
                        value={
                          reported_this_month_count &&
                          reported_this_month_count.reported_this_month_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_this_month_count.reported_this_month_count /
                                  data_total.device_count) *
                                  100
                              )
                            : 0
                        }
                        maxValue={100}
                        text={
                          reported_this_month_count &&
                          reported_this_month_count.reported_this_month_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_this_month_count.reported_this_month_count /
                                  data_total.device_count) *
                                  100
                              ) + "%"
                            : ""
                        }
                        styles={buildStyles({
                          pathColor: "#746198",
                          textColor: "#746198",
                          trailColor: "rgba(0, 0, 0, 0.15)",
                        })}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={16}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div style={{ color: "#746198", fontSize: "2rem" }}>
                      {loading_reported_this_month_count || loading_total ? (
                        <Loader />
                      ) : (
                        reported_this_month_count.reported_this_month_count
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      )}
                    </div>
                    <div style={{ color: "#8e9fb1" }}>Reported This Month</div>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Col>
          <Col xs={6} style={{ padding: 5, paddingTop: 0, paddingRight: 5 }}>
            <Panel
              className="panel"
              onClick={() => history.push("/devices/reported")}
              style={{ cursor: "pointer" }}
            >
              <Grid style={{ width: "100%" }}>
                <Row>
                  <Col xs={8}>
                    <div style={{ width: "5rem", height: "5rem" }}>
                      <CircularProgressbar
                        value={
                          reported_all_time_count &&
                          reported_all_time_count.reported_all_time_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_all_time_count.reported_all_time_count /
                                  data_total.device_count) *
                                  100
                              )
                            : 0
                        }
                        maxValue={100}
                        text={
                          reported_all_time_count &&
                          reported_all_time_count.reported_all_time_count &&
                          data_total &&
                          data_total.device_count
                            ? parseInt(
                                (reported_all_time_count.reported_all_time_count /
                                  data_total.device_count) *
                                  100
                              ) + "%"
                            : ""
                        }
                        styles={buildStyles({
                          pathColor: "#4781a7",
                          textColor: "#4781a7",
                          trailColor: "rgba(0, 0, 0, 0.15)",
                        })}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={16}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div style={{ color: "#4781a7", fontSize: "2rem" }}>
                      {loading_reported_all_time_count ? (
                        <Loader />
                      ) : (
                        reported_all_time_count.reported_all_time_count
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      )}
                    </div>
                    <div style={{ color: "#8e9fb1" }}>Reported</div>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Col>
        </Row>
      </Grid>
      <Grid
        className="panel"
        style={{ width: "100%", marginTop: "5px", padding: "10px" }}
      >
        <Row style={{ padding: "10px" }}>
          <Col xs={12} style={{ textAlign: "left" }}>
            <span style={{ color: "#8e9fb1" }}>
              <Icon icon="list" style={{ margin: "5px" }} /> Summary
            </span>
          </Col>
          <Col xs={12} style={{ textAlign: "right" }}>
            <Dropdown
              title="Import"
              icon={<Icon icon="file-upload" />}
              trigger={["hover", "click"]}
            >
              <Dropdown.Item
                icon={<Icon icon="file-upload" />}
                onSelect={() => setShowImportModal(true)}
              >
                Import Devices
              </Dropdown.Item>
              <Dropdown.Item
                icon={<Icon icon="plus" />}
                onSelect={() => setShowAddDeviceModal(true)}
              >
                Add Device
              </Dropdown.Item>
            </Dropdown>
            {/*
            <ButtonToolbar>
              <Whisper speaker={<Tooltip>Add Device</Tooltip>} placement="top">
                <Icon
                  size="2x"
                  onClick={() => setShowAddDeviceModal(true)}
                  icon="plus-square-o"
                  style={{ cursor: "pointer" }}
                />
              </Whisper>
              <Whisper
                speaker={<Tooltip>Import Devices</Tooltip>}
                placement="top"
              >
                <Icon
                  size="2x"
                  onClick={() => setShowImportModal(true)}
                  icon="file-upload"
                  style={{ cursor: "pointer" }}
                />
              </Whisper>
              <Button
                appearance="primary"
                className="button-shadow"
                size="xs"
                onClick={() => setShowAddDeviceModal(true)}
              >
                <Icon icon="plus-circle" /> Add Device
              </Button>
              <Button
                appearance="primary"
                className="button-shadow"
                size="xs"
                onClick={() => setShowImportModal(true)}
              >
                <Icon icon="import" /> Import Devices
              </Button>
            </ButtonToolbar>
*/}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              loading={loading}
              data={
                !loading && data && data.devicetypes ? data.devicetypes : []
              }
              style={{
                borderRadius: "5px",
                //boxShadow: "rgba(0, 0, 0, 0.1) 15px 0px 25px, rgba(0, 0, 0, 0.1) -15px 0px 25px",
                backgroundColor: "rgba(0,0,0,0.0)",
                //marginTop: "5px",
              }}
              //hover={false}
              //bordered
              //cellBordered
              autoHeight
            >
              <Table.Column flexGrow={2}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Device Type
                </Table.HeaderCell>
                <Table.Cell dataKey="devicetype_name">
                  {(devicetype) => {
                    return (
                      <span
                        onClick={() =>
                          history.push(
                            "/devices/reported/" + devicetype.devicetype_id
                          )
                        }
                        style={{
                          cursor: "pointer",
                          color: "#217FBC",
                          fontWeight: "bold",
                        }}
                      >
                        {devicetype.devicetype_name}
                      </span>
                    );
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell
                  style={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Count
                </Table.HeaderCell>
                <Table.Cell style={{ textAlign: "right" }}>
                  {(row) => {
                    return (
                      <DevicetypeCount devicetype_id={row.devicetype_id} />
                    );
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell
                  style={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Reported This Month
                </Table.HeaderCell>
                <Table.Cell>
                  {(devicetype) => (
                    <ReportedThisMonth
                      devicetype_id={devicetype.devicetype_id}
                    />
                  )}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell
                  style={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Reported All Time
                </Table.HeaderCell>
                <Table.Cell>
                  {(devicetype) => (
                    <ReportedAllTime devicetype_id={devicetype.devicetype_id} />
                  )}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell
                  style={{ fontWeight: "bold", textAlign: "right" }}
                >
                  <bold>
                    {monthly_event_count &&
                    monthly_event_count.monthly_event_count
                      ? monthly_event_count.monthly_event_count
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Events"
                      : "Events"}
                  </bold>
                </Table.HeaderCell>
                <Table.Cell>
                  {(devicetype) => (
                    <EventCount devicetype_id={devicetype.devicetype_id} />
                  )}
                </Table.Cell>
              </Table.Column>
              {/*
              <Table.Column flexGrow={1}>
                <Table.HeaderCell
                  style={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Cost
                </Table.HeaderCell>
                <Table.Cell dataKey="cost" />
              </Table.Column>
              */}
            </Table>
            {/*<Table.Pagination />*/}
          </Col>
        </Row>
      </Grid>
      <Modal
        show={showAddDeviceModal}
        onHide={() => setShowAddDeviceModal(false)}
        className="big-shadow"
      >
        <Modal.Header>
          <Modal.Title>Add Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="tag" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Device Name"
                  value={deviceName}
                  onChange={setDeviceName}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="hashtag" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Device Serial"
                  value={deviceSerial}
                  onChange={setDeviceSerial}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="object-group" className="subtle-color" />
                </InputGroup.Addon>
                <SelectPicker
                  placeholder="Device Type"
                  data={
                    !loading_devicetypes &&
                    devicetypes &&
                    devicetypes.devicetypes
                      ? devicetypes.devicetypes.map((devicetype) => ({
                          label: devicetype.devicetype_name,
                          value: devicetype.devicetype_id,
                        }))
                      : []
                  }
                  style={{
                    width: "100%",
                  }}
                  value={devicetype_id}
                  onChange={setDevicetype_id}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup
                style={{
                  width: "100%",
                  border: 0,
                  //backgroundColor: "rgba(0,0,0,0.3)",
                }}
              >
                <InputGroup.Addon>
                  <Icon icon="list" className="subtle-color" />
                </InputGroup.Addon>
                <SelectPicker
                  placeholder="Queue"
                  data={
                    !loading_queues && queues && queues.queues
                      ? queues.queues.map((queue) => ({
                          label: queue.queue_name,
                          value: queue.queue_id,
                        }))
                      : []
                  }
                  style={{
                    width: "100%",
                  }}
                  value={queue_id}
                  onChange={setQueue_id}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            //onClick={() => setShowAddDeviceModal(false)}
            appearance="primary"
            color="green"
            className="button-shadow"
            size="lg"
            disabled={
              !queue_id ||
              !devicetype_id ||
              deviceSerial.length == 0 ||
              deviceName.length == 0
            }
            onClick={() => {
              addDevice({
                variables: {
                  device_name: deviceName,
                  device_serial: deviceSerial,
                  devicetype_id,
                  queue_id,
                },
              }).then((res) => console.log({ res }));
              setDeviceName("");
              setDeviceSerial("");
              setDevicetype_id(0);
              setShowAddDeviceModal(false);
            }}
          >
            <Icon icon="plus-circle" /> Add
          </Button>
          <Button
            onClick={() => setShowAddDeviceModal(false)}
            appearance="subtle"
            //className="button-shadow"
            size="lg"
          >
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showImportModal}
        onHide={() => setShowImportModal(false)}
        className="big-shadow"
      >
        <Modal.Header>
          <Modal.Title>Import Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="object-group" className="subtle-color" />
                </InputGroup.Addon>
                <SelectPicker
                  placeholder="Device Type"
                  data={
                    !loading_devicetypes &&
                    devicetypes &&
                    devicetypes.devicetypes
                      ? devicetypes.devicetypes.map((devicetype) => ({
                          label: devicetype.devicetype_name,
                          value: devicetype.devicetype_id,
                        }))
                      : []
                  }
                  style={{
                    width: "100%",
                  }}
                  value={devicetype_id}
                  onChange={setDevicetype_id}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup
                style={{
                  width: "100%",
                  border: 0,
                  //backgroundColor: "rgba(0,0,0,0.3)",
                }}
              >
                <InputGroup.Addon>
                  <Icon icon="list" className="subtle-color" />
                </InputGroup.Addon>
                <SelectPicker
                  placeholder="Queue"
                  data={
                    !loading_queues && queues && queues.queues
                      ? queues.queues.map((queue) => ({
                          label: queue.queue_name,
                          value: queue.queue_id,
                        }))
                      : []
                  }
                  style={{
                    width: "100%",
                  }}
                  value={queue_id}
                  onChange={setQueue_id}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Uploader action="//upload" draggable>
                <div style={{ padding: "50px" }}>
                  Drag and Drop a file here to import
                </div>
              </Uploader>
            </FormGroup>
            <FormGroup>
              <Button
                onClick={() => setShowTemplate(!showTemplate)}
                appearance="subtle"
                size="xs"
              >
                {showTemplate ? "Hide " : ""}Template
              </Button>
            </FormGroup>
            <FormGroup>
              {showTemplate && (
                <Table
                  show={showTemplate}
                  data={[
                    { dn: "Name 1", ds: "Serial 1" },
                    { dn: "Name 2", ds: "Serial 2" },
                  ]}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell style={{ backgroundColor: "gray" }}>
                      device_name
                    </Table.HeaderCell>
                    <Table.Cell
                      dataKey="dn"
                      style={{ backgroundColor: "lightgray", color: "black" }}
                    />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell style={{ backgroundColor: "gray" }}>
                      device_serial
                    </Table.HeaderCell>
                    <Table.Cell
                      dataKey="ds"
                      style={{ backgroundColor: "lightgray", color: "black" }}
                    />
                  </Table.Column>
                </Table>
              )}
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            //onClick={() => setShowAddDeviceModal(false)}
            appearance="primary"
            color="green"
            className="button-shadow"
            size="lg"
          >
            <Icon icon="import" /> Import
          </Button>
          <Button
            onClick={() => setShowImportModal(false)}
            appearance="subtle"
            //className="button-shadow"
            size="lg"
          >
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
