import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  InputPicker,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  Avatar,
  Alert,
} from "rsuite";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

import Cookie from "js-cookie";
import config from "../../config";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

const token = Cookie.get(config.session_cookie_name);

export default (props) => {
  const { device_id } = props;

  const history = useHistory();

  const [device_name, setDevice_name] = useState("");
  const [device_serial, setDevice_serial] = useState("");
  const [devicetype_id, setDevicetype_id] = useState(0);
  const [queue_id, setQueue_id] = useState(0);

  /** Queries */
  const { data, loading, refetch } = useQuery(GQL.Queries.device, {
    variables: { device_id },
    onCompleted: (res) => {
      console.log({ res });
      setDevice_name(res.device.device_name);
      setDevice_serial(res.device.device_serial);
      setDevicetype_id(res.device.devicetype_id);
      setQueue_id(res.device.queue_id);
    },
  });
  const { data: devicetypes, loading: loading_devicetypes } = useQuery(
    GQL.Queries.devicetypes
  );
  const { data: queues, loading: loading_queues } = useQuery(
    GQL.Queries.queues
  );
  const { data: events, loading: loading_events } = useQuery(
    GQL.Queries.events,
    {
      variables: {
        device_id,
      },
    }
  );

  /** Mutations */
  const [update_device] = useMutation(GQL.Mutations.update_device);

  const limit = parseInt((window.innerHeight - 345) / 45);

  const hasChanges = () => {
    if (loading || !data || !data.device) return false;
    if (data.device.device_name != device_name) return true;
    if (data.device.device_serial != device_serial) return true;
    if (data.device.queue_id != queue_id) return true;
    if (data.device.devicetype_id != devicetype_id) return true;
    return false;
  };

  //console.log({ events });

  return (
    <Container
      style={{
        padding: 0,
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid
        //className="panel"
        style={{ width: "100%", margin: 0, padding: 0, marginTop: 15 }}
      >
        <Row>
          <Col xs={8} style={{ paddingBottom: "15px" }}>
            <Button onClick={() => history.goBack()} className="button-shadow">
              <Icon icon="arrow-left" /> Back
            </Button>
          </Col>
          <Col xs={16} style={{ textAlign: "left", paddingLeft: 5 }}>
            <div style={{ fontSize: 24, paddingLeft: 10 }}>
              <b>Events</b>
            </div>
          </Col>
        </Row>
        <Row style={{ padding: 0 }}>
          <Col xs={8}>
            <Panel
              bodyFill
              style={{
                display: "inline-block",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              className="login"
            >
              <div
                style={{
                  backgroundImage: "url(/images/profile-bg.jpg)",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "400px",
                }}
              >
                <img
                  src="/images/device_generic.png"
                  style={{
                    width: "400px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
              <Panel style={{ textAlign: "center" }}>
                <div>
                  <big>
                    <b>{device_name}</b>
                  </big>
                </div>
                <div>
                  <span>
                    {(data &&
                      data.device &&
                      data.device.devicetype &&
                      data.device.devicetype.devicetype_name) ||
                      "Type"}
                  </span>
                </div>
                <Form style={{ paddingTop: "15px" }}>
                  <FormGroup>
                    <InputGroup style={{ width: "100%", border: 0 }}>
                      <InputGroup.Addon>
                        <Icon icon="user" />
                      </InputGroup.Addon>
                      <FormControl
                        placeholder="Name"
                        value={device_name}
                        onChange={setDevice_name}
                      />
                      <FormControl
                        placeholder="Serial"
                        value={device_serial}
                        onChange={setDevice_serial}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup
                      style={{
                        width: "100%",
                        //backgroundColor: "#282F38"
                      }}
                    >
                      <InputGroup.Addon>
                        <Icon icon="cog" />
                      </InputGroup.Addon>
                      <SelectPicker
                        data={
                          loading_devicetypes
                            ? [{ label: "Loading...", value: 0 }]
                            : devicetypes.devicetypes.map((devicetype) => ({
                                label: devicetype.devicetype_name,
                                value: devicetype.devicetype_id,
                              }))
                        }
                        cleanable={false}
                        value={devicetype_id}
                        onChange={setDevicetype_id}
                        style={{ width: "100%" }}
                      />
                      <InputGroup.Addon>
                        <Icon icon="list" />
                      </InputGroup.Addon>
                      <SelectPicker
                        data={
                          loading_queues
                            ? [{ label: "Loading...", value: 0 }]
                            : queues.queues.map((queue) => ({
                                label: queue.queue_name,
                                value: queue.queue_id,
                              }))
                        }
                        cleanable={false}
                        value={queue_id}
                        onChange={setQueue_id}
                        style={{ width: "100%" }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      appearance="primary"
                      style={{ width: "50%" }}
                      size="lg"
                      disabled={!hasChanges()}
                      onClick={() => {
                        update_device({
                          variables: {
                            device_id,
                            device_name,
                            device_serial,
                            devicetype_id,
                            queue_id,
                          },
                        })
                          .then((res) => {
                            console.log({ update_device: res });
                            if (!res || !res.data.update_device) {
                              Alert.error("Error updating device");
                              return;
                            }
                            Alert.success("Updated device!");
                            refetch();
                          })
                          .catch((err) => Alert.error(err));
                      }}
                    >
                      <Icon icon="save" /> Save
                    </Button>
                    <Button
                      appearance="subtle"
                      style={{ width: "50%" }}
                      size="lg"
                      disabled={!hasChanges()}
                      onClick={() => history.goBack()}
                    >
                      <Icon icon="ban" /> Cancel
                    </Button>
                  </FormGroup>
                </Form>
              </Panel>
            </Panel>
          </Col>
          <Col xs={16} style={{ textAlign: "left", paddingLeft: "15px" }}>
            <Panel
              bodyFill
              className="panel"
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                height: window.innerHeight - 180,
                overflowY: "scroll",
              }}
            >
              <Table
                loading={loading_events}
                data={
                  events && events.events
                    ? events.events.map((e) => JSON.parse(e))
                    : []
                }
                autoHeight
                rowHeight={20}
                showHeader={false}
              >
                <Table.Column width={130}>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.Cell
                    style={{
                      padding: 1,
                      fontFamily: "monospace",
                      fontSize: "11px",
                    }}
                  >
                    {(row) => {
                      //console.log({ row });
                      return row && row.event_data && row.event_data.device_time
                        ? moment
                            .unix(row.event_data.device_time)
                            .format("YYYY/MM/DD HH:mm:ss")
                        : "N/A";
                    }}
                  </Table.Cell>
                </Table.Column>
                <Table.Column width={130}>
                  <Table.HeaderCell>Event</Table.HeaderCell>
                  <Table.Cell
                    style={{
                      padding: 1,
                      fontFamily: "monospace",
                      fontSize: "11px",
                    }}
                  >
                    {(row) => {
                      return row &&
                        row.event_data &&
                        row.event_data.conduit_event_description &&
                        row.event_data.conduit_color_code ? (
                        <span
                          style={{
                            color: "#" + row.event_data.conduit_color_code,
                          }}
                        >
                          {row.event_data.conduit_event_description}
                        </span>
                      ) : (
                        <span>Unknown</span>
                      );
                    }}
                  </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Raw Data</Table.HeaderCell>
                  <Table.Cell
                    style={{
                      padding: 1,
                      fontFamily: "monospace",
                      fontSize: "11px",
                    }}
                  >
                    {(row) => row.event_raw}
                  </Table.Cell>
                </Table.Column>
              </Table>
            </Panel>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
};
