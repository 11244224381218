import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Alert,
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  Avatar,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";
import sha512 from "js-sha512";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

import Cookie from "js-cookie";
import config from "../../config";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

const token = Cookie.get(config.session_cookie_name);

export default (props) => {
  const [user_password, setUser_password] = useState("");
  const [user_firstname, setUser_firstname] = useState("");
  const [user_lastname, setUser_lastname] = useState("");
  const [user_email, setUser_email] = useState("");
  const [user_id, setUser_id] = useState(0);

  const {
    data: user_data,
    loading: loading_user_data,
    refetch: refetch_user_data,
  } = useQuery(GQL.Queries.user_by_token, {
    variables: { token },
    onCompleted: (user) => {
      //console.log({ user });
      if (user && user.user_by_token) {
        setUser_id(user.user_by_token.user_id);
        setUser_firstname(user.user_by_token.user_firstname);
        setUser_lastname(user.user_by_token.user_lastname);
        setUser_email(user.user_by_token.user_email);
      } else {
        console.log("Could not load user from token");
      }
    },
  });
  const [update_user, { loading: loading_update_user }] = useMutation(
    GQL.Mutations.update_user
  );

  //const limit = parseInt((window.innerHeight - 345) / 45);

  const hasChanges = () => {
    console.log({
      user_data,
      user_password,
      user_firstname,
      user_lastname,
      user_email,
    });

    if (user_data && user_data.user_by_token) {
      if (user_password.length > 0) return true;
      if (user_firstname !== user_data.user_by_token.user_firstname)
        return true;
      if (user_lastname !== user_data.user_by_token.user_lastname) return true;
      if (user_email !== user_data.user_by_token.user_email) return true;
    }

    return false;
  };

  return (
    <Container
      style={{
        padding: 0,
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      {/*
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>Profile</b> Dashboard
            </div>
          </Col>
        </Row>
      </Grid>
    */}
      <Grid
        //className="panel"
        style={{ width: "100%" }}
      >
        <Row style={{ padding: 0 }}>
          <Col xs={24}>
            <Panel
              bodyFill
              style={{
                display: "inline-block",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              //className="login"
            >
              <div
                style={{
                  backgroundImage: "url(/images/profile-bg.jpg)",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "150px",
                }}
              />
              <Panel style={{ textAlign: "center" }}>
                <div>
                  <img src={"/images/mc3-logo.png"} width={100} />
                </div>
                <div>
                  <big>
                    <b>{user_firstname}</b> {user_lastname}
                  </big>
                </div>
                <div>
                  {user_data &&
                  user_data.user_by_token &&
                  user_data.user_by_token.admin ? (
                    <span>User</span>
                  ) : (
                    <span>Admin</span>
                  )}
                </div>
                <Form style={{ paddingTop: "15px" }}>
                  <FormGroup>
                    <InputGroup style={{ width: "100%", border: 0 }}>
                      <InputGroup.Addon>
                        <Icon icon="user" />
                      </InputGroup.Addon>
                      <FormControl
                        placeholder="First Name"
                        value={user_firstname}
                        onChange={setUser_firstname}
                      />
                      <FormControl
                        placeholder="Last Name"
                        value={user_lastname}
                        onChange={setUser_lastname}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup style={{ width: "100%", border: 0 }}>
                      <InputGroup.Addon>
                        <Icon icon="envelope" />
                      </InputGroup.Addon>
                      <FormControl
                        placeholder="E-Mail"
                        value={user_email}
                        onChange={setUser_email}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup style={{ width: "100%", border: 0 }}>
                      <InputGroup.Addon>
                        <Icon icon="lock" />
                      </InputGroup.Addon>
                      <FormControl
                        type="password"
                        placeholder="Fill in to change password"
                        value={user_password}
                        onChange={setUser_password}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      appearance="primary"
                      style={{ width: "50%" }}
                      size="lg"
                      disabled={!hasChanges() || loading_update_user}
                      onClick={() => {
                        update_user({
                          variables: {
                            user_id: user_data.user_by_token.user_id,
                            user_firstname,
                            user_lastname,
                            user_email,
                            ...(user_password && user_password.length > 0
                              ? { user_password: sha512(user_password) }
                              : {}),
                          },
                        }).then((data) => {
                          console.log({ data });
                          setUser_password("");
                          refetch_user_data();
                          Alert.success("User details saved!");
                        });
                      }}
                    >
                      {loading_update_user ? (
                        <>
                          <Loader /> Saving...
                        </>
                      ) : (
                        <>
                          <Icon icon="save" /> Save
                        </>
                      )}
                    </Button>
                    <Button
                      appearance="subtle"
                      style={{ width: "50%" }}
                      size="lg"
                    >
                      <Icon icon="ban" /> Cancel
                    </Button>
                  </FormGroup>
                </Form>
              </Panel>
            </Panel>
          </Col>
          {/*
          <Col xs={12} style={{ textAlign: "left", paddingLeft: "15px" }}>
            <div style={{ fontSize: 24, paddingLeft: 10 }}>
              <b>Activities</b>
            </div>
            <Panel
              bodyFill
              className="small-shadow"
              style={{ backgroundColor: "rgba(0,0,0,0.3)", marginTop: 15 }}
            >
              <div style={{ padding: 20, paddingBottom: 0 }}>
                <b style={{ color: "#0077BE" }}>Meng Yang</b>
                {" - "}
                <small>June 13, 2021 at 01:59 PM</small>
              </div>
              <div style={{ padding: 2, paddingLeft: 20 }}>
                New client added: Client 1
              </div>
              <div
                style={{
                  padding: 5,
                  paddingLeft: 20,
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              >
                <small>
                  1 Hour <b>.</b> <a href="#">Details</a>
                </small>
              </div>
            </Panel>
            <Panel
              bodyFill
              className="small-shadow"
              style={{ backgroundColor: "rgba(0,0,0,0.3)", marginTop: 15 }}
            >
              <div style={{ padding: 20, paddingBottom: 0 }}>
                <b style={{ color: "#0077BE" }}>Meng Yang</b>
                {" - "}
                <small>June 13, 2021 at 01:59 PM</small>
              </div>
              <div style={{ padding: 2, paddingLeft: 20 }}>
                New client added: Client 1
              </div>
              <div
                style={{
                  padding: 5,
                  paddingLeft: 20,
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              >
                <small>
                  1 Hour <b>.</b> <a href="#">Details</a>
                </small>
              </div>
            </Panel>
            <Panel
              bodyFill
              className="small-shadow"
              style={{ backgroundColor: "rgba(0,0,0,0.3)", marginTop: 15 }}
            >
              <div style={{ padding: 20, paddingBottom: 0 }}>
                <b style={{ color: "#0077BE" }}>Meng Yang</b>
                {" - "}
                <small>June 13, 2021 at 01:59 PM</small>
              </div>
              <div style={{ padding: 2, paddingLeft: 20 }}>
                New client added: Client 1
              </div>
              <div
                style={{
                  padding: 5,
                  paddingLeft: 20,
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              >
                <small>
                  1 Hour <b>.</b> <a href="#">Details</a>
                </small>
              </div>
            </Panel>
            <Panel
              bodyFill
              className="small-shadow"
              style={{ backgroundColor: "rgba(0,0,0,0.3)", marginTop: 15 }}
            >
              <div style={{ padding: 20, paddingBottom: 0 }}>
                <b style={{ color: "#0077BE" }}>Meng Yang</b>
                {" - "}
                <small>June 13, 2021 at 01:59 PM</small>
              </div>
              <div style={{ padding: 2, paddingLeft: 20 }}>
                New client added: Client 1
              </div>
              <div
                style={{
                  padding: 5,
                  paddingLeft: 20,
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              >
                <small>
                  1 Hour <b>.</b> <a href="#">Details</a>
                </small>
              </div>
            </Panel>
          </Col>
              */}
        </Row>
      </Grid>
    </Container>
  );
};
