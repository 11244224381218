import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

export default (props) => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [user_name, setUser_name] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [user_firstname, setUser_firstname] = useState("");
  const [user_lastname, setUser_lastname] = useState("");
  const [email, setEmail] = useState("");

  const limit = parseInt((window.innerHeight - 345) / 45);
  const [page, setPage] = useState(1);

  const { data, loading, refetch } = useQuery(GQL.Queries.users, {
    variables: {
      offset: (page - 1) * limit,
      limit,
    },
  });
  const {
    data: count,
    loading: loading_count,
    refetch: refetch_count,
  } = useQuery(GQL.Queries.user_count);

  console.log({ limit, count });

  return (
    <Container
      style={{
        padding: "25px",
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>Users</b> Dashboard
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid
        className="panel"
        style={{ width: "100%", marginTop: "15px", padding: "10px" }}
      >
        <Row style={{ padding: "10px" }}>
          <Col xs={12} style={{ textAlign: "left" }}>
            <span style={{ color: "#8e9fb1" }}>
              <Icon icon="people-group" style={{ margin: "5px" }} /> User List
            </span>
          </Col>
          <Col xs={12} style={{ textAlign: "right" }}>
            <ButtonToolbar>
              <Whisper
                speaker={<Tooltip>Export CSV</Tooltip>}
                trigger={["hover"]}
                placement="top"
              >
                <Button appearance="subtle">
                  <Icon icon="file-text-o" />
                </Button>
              </Whisper>
              <Whisper
                speaker={<Tooltip>Export Excel</Tooltip>}
                trigger={["hover"]}
                placement="top"
              >
                <Button appearance="subtle">
                  <Icon icon="file-excel-o" />
                </Button>
              </Whisper>
              <Whisper
                speaker={<Tooltip>Export PDF</Tooltip>}
                trigger={["hover"]}
                placement="top"
              >
                <Button appearance="subtle">
                  <Icon icon="file-pdf-o" />
                </Button>
              </Whisper>
              <Button
                onClick={() => setShowAddUserModal(true)}
                className="button-shadow"
              >
                <Icon icon="plus" /> Add User
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              data={loading ? [] : data.users}
              style={{
                borderRadius: "5px",
                //boxShadow: "rgba(0, 0, 0, 0.1) 15px 0px 25px, rgba(0, 0, 0, 0.1) -15px 0px 25px",
                backgroundColor: "rgba(0,0,0,0.0)",
                //marginTop: "5px",
              }}
              //hover={false}
              //bordered
              //cellBordered
              autoHeight
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Name
                </Table.HeaderCell>
                <Table.Cell>
                  {(user) => {
                    return user.user_firstname + " " + user.user_lastname;
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  Username
                </Table.HeaderCell>
                <Table.Cell dataKey="user_name" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell style={{ fontWeight: "bold" }}>
                  E-Mail
                </Table.HeaderCell>
                <Table.Cell dataKey="user_email" />
              </Table.Column>
            </Table>
            <Pagination
              activePage={page}
              total={
                !loading_count && count && count.user_count
                  ? count.user_count
                  : 0
              }
              onSelect={(page) => {
                setPage(page);
                refetch();
              }}
              limit={limit}
              ellipsis={true}
              pages={loading_count ? 1 : Math.ceil(count.user_count / limit)}
              first
              last
              boundaryLinks
            />
          </Col>
        </Row>
      </Grid>
      <Modal
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
        className="big-shadow"
      >
        <Modal.Header>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="tag" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="First Name"
                  value={user_firstname}
                  onChange={setUser_firstname}
                />
                <FormControl
                  placeholder="Last Name"
                  value={user_lastname}
                  onChange={setUser_lastname}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="envelope" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="E-Mail"
                  value={email}
                  onChange={setEmail}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="user" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  placeholder="Username"
                  value={user_name}
                  onChange={setUser_name}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="lock" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={setPassword}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%", border: 0 }}>
                <InputGroup.Addon>
                  <Icon icon="lock" className="subtle-color" />
                </InputGroup.Addon>
                <FormControl
                  type="password"
                  placeholder="Confirm Password"
                  value={confirm}
                  onChange={setConfirm}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            //onClick={() => setShowAddDeviceModal(false)}
            appearance="primary"
            color="green"
            className="button-shadow"
            size="lg"
          >
            <Icon icon="plus-circle" /> Add
          </Button>
          <Button
            onClick={() => setShowAddUserModal(false)}
            appearance="subtle"
            //className="button-shadow"
            size="lg"
          >
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
