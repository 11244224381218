import React from "react";
import { Container, Content } from "rsuite";

import Header from "./header";
import Menu from "./menu";
import Devices from "./devices";
import Users from "./users";
import Clients from "./clients";
import Profile from "./profile";
import Queues from "./queues";
import Billing from "./billing";
import Api from "./api";

import { useApp } from "../AppStore";

const isBigScreen = window.innerWidth > 1920;

export default (props) => {
  const { page, id } = props;
  const [{ selectedPage: globalSelectedPage, menuExpanded }, actions] =
    useApp();
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  //const isMobile = window.innerWidth < 992;

  const selectedPage = page ? page : globalSelectedPage;

  return (
    <Container style={{ height: "100vh" }}>
      <Header page={selectedPage} />
      <Container>
        <Menu page={selectedPage} />
        {selectedPage == "devices" && <Devices {...props} id={id} />}
        {selectedPage == "users" && <Users {...props} id={id} />}
        {selectedPage == "clients" && <Clients {...props} id={id} />}
        {selectedPage == "profile" && <Profile {...props} />}
        {selectedPage == "queues" && <Queues {...props} id={id} />}
        {selectedPage == "billing" && <Billing {...props} id={id} />}
        {selectedPage == "api" && <Api {...props} id={id} />}
      </Container>
    </Container>
  );
};
