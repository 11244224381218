import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  InputPicker,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  TreePicker,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

export default (props) => {
  const [billingMonth, setBillingMonth] = useState("");
  const [pickerData, setPickerData] = useState([]);
  const [tree, setTree] = useState({});
  const [disabledTreeOptions, setDisabledTreeOptions] = useState([]);
  const [getBill, { data: bill, loading: loading_bill }] = useLazyQuery(
    GQL.Queries.monthlyBill
  );
  const { data, loading, refetch } = useQuery(GQL.Queries.billing, {
    onCompleted: async (data) => {
      console.log({ data });
      let result = [];
      let year;
      let months = [];
      let disabledTreeOptions = [];
      let pickerData = [];

      // Array of paths to tree object
      data.billing.forEach((p, i) => {
        const pathItems = p.name.split("/");

        pickerData.push({
          label:
            moment(pathItems[3], "MM").format("MMMM") + ", " + pathItems[2],
          value: p,
          year: pathItems[2],
        });

        if (pathItems.length > 3) {
          if (year && year.label) {
            if (year.label == pathItems[2]) {
              months.push({
                label:
                  moment(pathItems[3], "MM").format("MMMM") +
                  ", " +
                  pathItems[2],
                value: p,
              });
            } else {
              year.children = months;
              result.push(year);
              months = [];
              year = null;
            }
          }
          year = {
            label: pathItems[2],
            value: pathItems[2],
            children: [
              {
                label:
                  moment(pathItems[3], "MM").format("MMMM") +
                  ", " +
                  pathItems[2],
                value: p,
              },
            ],
          };
          if (!disabledTreeOptions.includes(pathItems[2])) {
            disabledTreeOptions.push(pathItems[2]);
          }
        }
      });
      result.push(year);
      //console.log({ disabledTreeOptions });
      setTree(result);
      setDisabledTreeOptions(disabledTreeOptions);
      setPickerData(pickerData);
    },
  });

  useEffect(() => {
    console.log({ billingMonth });
    getBill({
      variables: {
        path: billingMonth.name,
      },
    });
  }, [billingMonth]);

  return (
    <Container
      style={{
        padding: "25px",
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>Billing</b> Dashboard
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid
        className="panel"
        style={{
          width: "100%",
          marginTop: "15px",
          padding: "10px",
        }}
      >
        <Row style={{ padding: "10px" }}>
          <Col xs={12} style={{ textAlign: "left" }}>
            <SelectPicker
              data={pickerData}
              groupBy="year"
              placeholder="Select Billing Month"
              //placement="bottomEnd"
              sort={(isGroup) =>
                isGroup
                  ? (a, b) => (a.year > b.year ? 1 : -1)
                  : (a, b) => (a.value > b.value ? 1 : -1)
              }
              cleanable={false}
              onChange={setBillingMonth}
              value={billingMonth}
            />
          </Col>
          <Col xs={12} style={{ textAlign: "right" }}>
            <ButtonToolbar>
              <Whisper
                speaker={<Tooltip>Export CSV</Tooltip>}
                trigger={["hover"]}
                placement="top"
              >
                <Button appearance="subtle">
                  <Icon icon="file-text-o" />
                </Button>
              </Whisper>
              <Whisper
                speaker={<Tooltip>Export Excel</Tooltip>}
                trigger={["hover"]}
                placement="top"
              >
                <Button appearance="subtle">
                  <Icon icon="file-excel-o" />
                </Button>
              </Whisper>
              <Whisper
                speaker={<Tooltip>Export PDF</Tooltip>}
                trigger={["hover"]}
                placement="top"
              >
                <Button appearance="subtle">
                  <Icon icon="file-pdf-o" />
                </Button>
              </Whisper>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {/*<TreePicker data={tree} disabledTreeOptions={disabledTreeOptions} />*/}
            <Table
              loading={loading_bill}
              data={
                bill && bill.monthlyBill && bill.monthlyBill.devices
                  ? bill.monthlyBill.devices
                  : []
              }
              virtualized
              height={window.innerHeight - 210}
              style={{ width: "100%" }}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>
                  <strong>
                    {bill && bill.monthlyBill && bill.monthlyBill.deviceCount
                      ? bill.monthlyBill.deviceCount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "
                      : ""}
                    Devices
                  </strong>
                </Table.HeaderCell>
                <Table.Cell dataKey="device_name" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>
                  <strong>Serial</strong>
                </Table.HeaderCell>
                <Table.Cell dataKey="device_serial" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>
                  <strong>
                    {bill && bill.monthlyBill && bill.monthlyBill.eventCount
                      ? bill.monthlyBill.eventCount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "
                      : ""}
                    Total Events
                  </strong>
                </Table.HeaderCell>
                <Table.Cell dataKey="monthly_count">
                  {(device) =>
                    device.monthly_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                </Table.Cell>
              </Table.Column>
            </Table>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
};
