import gql from "graphql-tag";

export default {
  Queries: {
    device_report_count: gql`
      query device_report_count($device_id: Int!) {
        device_report_count(device_id: $device_id)
      }
    `,
    allChildAccountIds: gql`
      query allChildAccountIds {
        allChildAccountIds
      }
    `,
    device_lastreport: gql`
      query devices($id: Int!) {
        devices(id: $id) {
          id
          last_report {
            location {
              latitude
              longitude
            }
            location_accuracy
            address
            send_time
            battery_level
            event_type_id
          }
        }
      }
    `,
    device: gql`
      query device($device_id: Int!) {
        device(device_id: $device_id) {
          device_id
          client_id
          devicetype_id
          devicetype {
            devicetype_name
          }
          device_name
          device_serial
          custom_serial
          device_status
          queue_id
          last_reported
          date_added
          daily_count
          monthly_count
          daily_threshold
        }
      }
    `,
    reported_count: gql`
      query reported_count($start: String) {
        reported_count(start: $start)
      }
    `,
    devicetype: gql`
      query devicetype($devicetype_id: Int!) {
        devicetype(devicetype_id: $devicetype_id) {
          devicetype_id
          devicetype_name
        }
      }
    `,
    queues: gql`
      query queues {
        queues {
          queue_id
          queue_name
        }
      }
    `,
    devices: gql`
      query devices(
        $client_id: Int
        $limit: Int
        $offset: Int
        $devicetype_id: Int
      ) {
        devices(
          client_id: $client_id
          limit: $limit
          offset: $offset
          devicetype_id: $devicetype_id
        ) {
          device_id
          client_id
          devicetype_id
          devicetype {
            devicetype_name
          }
          device_name
          device_serial
          custom_serial
          device_status
          queue_id
          last_reported
          date_added
          daily_count
          monthly_count
          daily_threshold
        }
      }
    `,
    devices_with_last_report: gql`
      {
        devices {
          id
          name
          attached_to
          imei
          device_mode_id
          device_type_id
          device_mode {
            name
          }
          last_report {
            location {
              latitude
              longitude
            }
            location_accuracy
            address
            send_time
            battery_level
            event_type_id
          }
        }
      }
    `,
    push_command_count: gql`
      query push_command_count($device_id: Int) {
        push_command_count(device_id: $device_id)
      }
    `,
    push_commands: gql`
      query push_commands($device_id: Int, $limit: Int, $offset: Int) {
        push_commands(
          device_id: $device_id
          limit: $limit
          offset: $offset
          sort_desc: true
        ) {
          id
          command_id
          command_type
          command
          payload
          sent
          created_at
        }
      }
    `,
    device_count: gql`
      query device_count {
        device_count
      }
    `,
    devicetype_count: gql`
      query devicetype_count($devicetype_id: Int!) {
        devicetype_count(devicetype_id: $devicetype_id)
      }
    `,
    reported_today_devices: gql`
      query reported_today_devices(
        $devicetype_id: Int
        $offset: Int
        $limit: Int
      ) {
        reported_today_devices(
          devicetype_id: $devicetype_id
          offset: $offset
          limit: $limit
        ) {
          device_id
          client_id
          devicetype_id
          devicetype {
            devicetype_name
          }
          device_name
          device_serial
          custom_serial
          device_status
          queue_id
          last_reported
          date_added
          daily_count
          monthly_count
          daily_threshold
        }
      }
    `,
    reported_today_count: gql`
      query reported_today_count($devicetype_id: Int) {
        reported_today_count(devicetype_id: $devicetype_id)
      }
    `,
    reported_this_week_devices: gql`
      query reported_this_week_devices(
        $devicetype_id: Int
        $offset: Int
        $limit: Int
      ) {
        reported_this_week_devices(
          devicetype_id: $devicetype_id
          offset: $offset
          limit: $limit
        ) {
          device_id
          client_id
          devicetype_id
          devicetype {
            devicetype_name
          }
          device_name
          device_serial
          custom_serial
          device_status
          queue_id
          last_reported
          date_added
          daily_count
          monthly_count
          daily_threshold
        }
      }
    `,
    reported_this_week_count: gql`
      query reported_this_week_count($devicetype_id: Int) {
        reported_this_week_count(devicetype_id: $devicetype_id)
      }
    `,
    reported_this_month_devices: gql`
      query reported_this_month_devices(
        $devicetype_id: Int
        $offset: Int
        $limit: Int
      ) {
        reported_this_month_devices(
          devicetype_id: $devicetype_id
          offset: $offset
          limit: $limit
        ) {
          device_id
          client_id
          devicetype_id
          devicetype {
            devicetype_name
          }
          device_name
          device_serial
          custom_serial
          device_status
          queue_id
          last_reported
          date_added
          daily_count
          monthly_count
          daily_threshold
        }
      }
    `,
    reported_this_month_count: gql`
      query reported_this_month_count($devicetype_id: Int) {
        reported_this_month_count(devicetype_id: $devicetype_id)
      }
    `,
    reported_all_time_devices: gql`
      query reported_all_time_devices(
        $devicetype_id: Int
        $offset: Int
        $limit: Int
      ) {
        reported_all_time_devices(
          devicetype_id: $devicetype_id
          offset: $offset
          limit: $limit
        ) {
          device_id
          client_id
          devicetype_id
          devicetype {
            devicetype_name
          }
          device_name
          device_serial
          custom_serial
          device_status
          queue_id
          last_reported
          date_added
          daily_count
          monthly_count
          daily_threshold
        }
      }
    `,
    reported_all_time_count: gql`
      query reported_all_time_count($devicetype_id: Int) {
        reported_all_time_count(devicetype_id: $devicetype_id)
      }
    `,
    monthly_event_count: gql`
      query monthly_event_count($devicetype_id: Int) {
        monthly_event_count(devicetype_id: $devicetype_id)
      }
    `,
    device_reports: gql`
      query device_reports(
        $device_id: Int!
        $start: String
        $end: String
        $offset: Int
        $limit: Int
      ) {
        device_reports(
          device_id: $device_id
          start: $start
          end: $end
          offset: $offset
          limit: $limit
        ) {
          id
          location {
            latitude
            longitude
          }
          address
          send_time
          signal_strength
          gps_time
          event_type_id
          event_type {
            name
          }
          location_accuracy
          payload
          battery_level
          created_at
        }
      }
    `,
    geofences: gql`
      query geofences($device_id: Int) {
        device_geofences(device_id: $device_id) {
          id
          name
          trigger
          radius
          latitude
          longitude
          address
        }
      }
    `,
    device_modes: gql`
      query device_modes($device_type_id: Int) {
        device_modes(device_type_id: $device_type_id) {
          id
          name
          command
        }
      }
    `,
    devicetypes: gql`
      query devicetypes(
        $limit: Int
        $offset: Int
        $only_show_used_devicetypes: Boolean
      ) {
        devicetypes(
          limit: $limit
          offset: $offset
          only_show_used_devicetypes: $only_show_used_devicetypes
        ) {
          devicetype_id
          devicetype_name
        }
      }
    `,
    unsent_push_commands: gql`
      query push_commands($device_id: Int) {
        push_commands(device_id: $device_id, sent: false) {
          id
        }
      }
    `,
    sim_providers: gql`
      {
        sim_providers {
          id
          name
        }
      }
    `,
    repo_tokens: gql`
      query repo_tokens($device_id: Int) {
        repo_tokens(device_id: $device_id) {
          id
          email
          token
          active
          expires_at
        }
      }
    `,
    events: gql`
      query events($device_id: Int!) {
        events(device_id: $device_id)
      }
    `,
  },
  Mutations: {
    add_device: gql`
      mutation add_device(
        $device_name: String
        $device_serial: String
        $queue_id: Int
        $devicetype_id: Int
      ) {
        add_device(
          device_name: $device_name
          device_serial: $device_serial
          queue_id: $queue_id
          devicetype_id: $devicetype_id
        )
      }
    `,
    update_device: gql`
      mutation update_device(
        $device_id: Int!
        $device_name: String
        $device_serial: String
        $queue_id: Int
        $devicetype_id: Int
      ) {
        update_device(
          device_id: $device_id
          device_name: $device_name
          device_serial: $device_serial
          queue_id: $queue_id
          devicetype_id: $devicetype_id
        )
      }
    `,
  },
};
