import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  HelpBlock,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

const subtitles = {
  reported_today: "Reported Today",
  reported_this_week: "Reported This Week",
  reported_this_month: "Reported This Month",
};

export default (props) => {
  const { id, subpage } = props;
  const limit = parseInt((window.innerHeight - 375) / 45);
  const history = useHistory();
  const client = useApolloClient();

  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState(id);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(subpage);

  const {
    data: devicetype,
    loading: loading_devicetype,
    refetch: refetch_devicetype,
  } = useQuery(GQL.Queries.devicetype, {
    variables: {
      devicetype_id: id,
    },
  });
  const { data: devicetypes, loading: loading_devicetypes } = useQuery(
    GQL.Queries.devicetypes
  );

  /*
  const [reportedToday, { loading: loadingToday, refetch: refetchToday }] =
    useLazyQuery(GQL.Queries.reported_today_devices, {
      onCompleted: (data) => {
        console.log({ reportedToday: data });
        setResults(data.reported_today_devices);
      },
    });
  const [
    reportedTodayCount,
    { loading: loadingTodayCount, refetch: refetchTodayCount },
  ] = useLazyQuery(GQL.Queries.reported_today_count, {
    onCompleted: (data) => {
      setTotal(data.reported_today_count);
    },
  });
  const [
    reportedThisMonth,
    { loading: loadingThisMonth, refetch: refetchThisMonth },
  ] = useLazyQuery(GQL.Queries.reported_this_month_devices, {
    onCompleted: (data) => {
      console.log({ reportedThisMonth: data });
      setResults(data.reported_this_month_devices);
    },
  });
  const [
    reportedThisMonthCount,
    { loading: loadingThisMonthCount, refetch: refetchThisMonthCount },
  ] = useLazyQuery(GQL.Queries.reported_this_month_count, {
    onCompleted: (data) => {
      setTotal(data.reported_this_month_count);
    },
  });
  */

  useEffect(() => {
    //console.log({ selectedTimeFrame });

    if (selectedTimeFrame === "reported_this_month") {
      client
        .query({
          query: GQL.Queries.reported_this_month_devices,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
            limit,
            offset: (page - 1) * limit,
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setResults(res.data.reported_this_month_devices || []);
        });
      client
        .query({
          query: GQL.Queries.reported_this_month_count,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setTotal(res.data.reported_this_month_count || 0);
        });
    } else if (selectedTimeFrame === "reported_this_week") {
      client
        .query({
          query: GQL.Queries.reported_this_week_devices,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
            limit,
            offset: (page - 1) * limit,
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setResults(res.data.reported_this_week_devices || []);
        });
      client
        .query({
          query: GQL.Queries.reported_this_week_count,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setTotal(res.data.reported_this_week_count || 0);
        });
    } else if (selectedTimeFrame === "reported_today") {
      client
        .query({
          query: GQL.Queries.reported_today_devices,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
            limit,
            offset: (page - 1) * limit,
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setResults(res.data.reported_today_devices || []);
        });
      client
        .query({
          query: GQL.Queries.reported_today_count,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          //console.log({ today_count: res });
          setTotal(res.data.reported_today_count || 0);
        });
    } else {
      client
        .query({
          query: GQL.Queries.reported_all_time_devices,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
            limit,
            offset: (page - 1) * limit,
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          setResults(res.data.reported_all_time_devices || []);
        });
      client
        .query({
          query: GQL.Queries.reported_all_time_count,
          variables: {
            ...(selectedDeviceTypeId
              ? { devicetype_id: selectedDeviceTypeId }
              : {}),
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          //console.log({ today_count: res });
          setTotal(res.data.reported_all_time_count || 0);
        });
    }
  }, [page, selectedDeviceTypeId, selectedTimeFrame]);

  //console.log({ offset: (page - 1) * limit });

  return (
    <Grid
      className="panel"
      style={{ width: "100%", marginTop: "15px", padding: "10px" }}
    >
      <Row style={{ padding: "10px" }}>
        <Col xs={12}>
          <Button size="small" onClick={() => history.goBack()}>
            <span>
              <Icon icon="arrow-left" /> back
            </span>
          </Button>
        </Col>
        <Col xs={12} style={{ textAlign: "right" }}>
          <ButtonToolbar>
            <SelectPicker
              placeholder="Device Type"
              data={
                loading_devicetypes
                  ? []
                  : devicetypes.devicetypes.map((dt) => ({
                      label: dt.devicetype_name,
                      value: dt.devicetype_id,
                    })) || []
              }
              cleanable={true}
              value={selectedDeviceTypeId}
              onChange={setSelectedDeviceTypeId}
            />
            <SelectPicker
              placeholder="Reported"
              data={[
                { label: "Today", value: "reported_today" },
                { label: "This Week", value: "reported_this_week" },
                { label: "This Month", value: "reported_this_month" },
              ]}
              searchable={false}
              cleanable={true}
              value={selectedTimeFrame}
              onChange={setSelectedTimeFrame}
            />
          </ButtonToolbar>
        </Col>
      </Row>
      <Row style={{ padding: "10px" }}>
        <Col xs={12} style={{ textAlign: "left" }}>
          <span style={{ color: "#8e9fb1" }}>
            {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {" Filtered Devices"}
          </span>
        </Col>
        <Col xs={12} style={{ textAlign: "right" }}>
          <ButtonToolbar>
            <Whisper
              speaker={<Tooltip>Export CSV</Tooltip>}
              trigger={["hover"]}
              placement="top"
            >
              <Button appearance="subtle">
                <Icon icon="file-text-o" />
              </Button>
            </Whisper>
            <Whisper
              speaker={<Tooltip>Export Excel</Tooltip>}
              trigger={["hover"]}
              placement="top"
            >
              <Button appearance="subtle">
                <Icon icon="file-excel-o" />
              </Button>
            </Whisper>
            <Whisper
              speaker={<Tooltip>Export PDF</Tooltip>}
              trigger={["hover"]}
              placement="top"
            >
              <Button appearance="subtle">
                <Icon icon="file-pdf-o" />
              </Button>
            </Whisper>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            //loading={loading}
            data={results}
            style={{
              borderRadius: "5px",
              //boxShadow: "rgba(0, 0, 0, 0.1) 15px 0px 25px, rgba(0, 0, 0, 0.1) -15px 0px 25px",
              backgroundColor: "rgba(0,0,0,0.0)",
              //marginTop: "5px",
            }}
            //hover={false}
            //bordered
            //cellBordered
            autoHeight
            onRowClick={(device) => {
              console.log({ device });
              history.push("/devices/details/" + device.device_id);
            }}
          >
            <Table.Column flexGrow={2}>
              <Table.HeaderCell style={{ fontWeight: "bold" }}>
                Device Name
              </Table.HeaderCell>
              <Table.Cell dataKey="device_name" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell
                style={{ fontWeight: "bold", textAlign: "right" }}
              >
                Device Type
              </Table.HeaderCell>
              <Table.Cell
                dataKey="devicetype.devicetype_name"
                style={{ textAlign: "right" }}
              >
                {(device) => device.devicetype.devicetype_name}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell
                style={{ fontWeight: "bold", textAlign: "right" }}
              >
                Device Serial
              </Table.HeaderCell>
              <Table.Cell
                dataKey="device_serial"
                style={{ textAlign: "right" }}
              />
            </Table.Column>
            {/*
            <Table.Column flexGrow={1}>
              <Table.HeaderCell
                style={{ fontWeight: "bold", textAlign: "right" }}
              >
                Event Count
              </Table.HeaderCell>
              <Table.Cell
                dataKey="monthly_count"
                style={{ textAlign: "right" }}
              >
                {(device) =>
                  device.monthly_count
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              </Table.Cell>
            </Table.Column>
              */}
            <Table.Column flexGrow={1}>
              <Table.HeaderCell
                style={{ fontWeight: "bold", textAlign: "right" }}
              >
                Last Reported
              </Table.HeaderCell>
              <Table.Cell style={{ textAlign: "right" }}>
                {(device) =>
                  moment
                    .unix(device.last_reported / 1000)
                    .format("YYYY-MM-DD HH:mm:ss")
                }
              </Table.Cell>
            </Table.Column>
          </Table>
          <Pagination
            activePage={page}
            total={total}
            onSelect={(page) => {
              setPage(page);
            }}
            limit={limit}
            ellipsis={true}
            pages={Math.ceil(total / limit)}
            first
            last
            boundaryLinks
            maxButtons={10}
          />
        </Col>
      </Row>
    </Grid>
  );
};
