import gql from "graphql-tag";

export default {
  Queries: {
    clients: gql`
      query clients {
        clients {
          client_id
          client_name
          client_address
          client_city
          client_state
          client_zip
          client_email
          client_phone
          billing_current
          createdAt
        }
      }
    `,
    client_count: gql`
      query client_count {
        client_count
      }
    `,
  },
  Mutations: {},
};
