import gql from "graphql-tag";

export default {
  Queries: {
    billing: gql`
      query billing($year: Int, $month: Int) {
        billing(year: $year, month: $month) {
          name
        }
      }
    `,
    monthlyBill: gql`
      query monthlyBill($path: String!) {
        monthlyBill(path: $path) {
          deviceCount
          eventCount
          devices {
            device_id
            device_serial
            device_name
            monthly_count
          }
        }
      }
    `,
  },
  Mutations: {},
};
