import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  Header,
  FormControl,
  Form,
  FormGroup,
  Dropdown,
  Uploader,
  HelpBlock,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

import Summary from "./summary";
import Reported from "./reported";
import Device from "./device";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

const titleMap = {
  details: {
    title: "Device",
    subtitle: "Details",
  },
  reported: {
    title: "Devices",
    subtitle: "Reported",
  },
  reported_today: {
    title: "Devices",
    subtitle: "Reported",
  },
  reported_this_week: {
    title: "Devices",
    subtitle: "Reported",
  },
  reported_this_month: {
    title: "Devices",
    subtitle: "Reported",
  },
  summary: {
    title: "Devices",
    subtitle: "Statistics & Dashboard",
  },
};

export default (props) => {
  const renderContent = () => {
    if (
      props.match.params.subpage &&
      [
        "reported",
        "reported_today",
        "reported_this_week",
        "reported_this_month",
      ].includes(props.match.params.subpage)
    ) {
      return (
        <Reported
          subpage={props.match.params.subpage}
          id={parseInt(props.match.params.id)}
        />
      );
    } else if (props.match.params.subpage === "details") {
      return <Device device_id={parseInt(props.match.params.id)} />;
    } else {
      return <Summary />;
    }
  };

  //console.log({ props });

  return (
    <Container
      style={{
        padding: "25px",
        paddingTop: 0,
        textAlign: "left",
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={12} style={{ textAlign: "left" }}>
            <div>
              <b style={{ fontSize: 24 }}>
                {titleMap[props.match.params.subpage || "summary"].title ||
                  "Devices"}
              </b>{" "}
              {titleMap[props.match.params.subpage || "summary"].subtitle ||
                "Statistics & Dashboard"}
            </div>
          </Col>
        </Row>
      </Grid>
      {renderContent()}
    </Container>
  );
};
