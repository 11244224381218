import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  InputGroup,
  Input,
  Badge,
  Button,
  Container,
  Icon,
  Nav,
  Navbar,
  Modal,
  Dropdown,
  Drawer,
  Panel,
  Table,
  Grid,
  Col,
  Row,
} from "rsuite";

import { useApp } from "../AppStore";
import useDebounce from "../hooks/usedebounce";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import GQL from "./graphql";
//import AccountDetails from "./accounts/details";
import Cookie, { set } from "js-cookie";
import config from "../config";
import Profile from "./profile";

const isMobile = window.innerWidth < 992;
const isBigScreen = window.innerWidth > 1920;

const pageTitles = {
  devices: "Devices",
  users: "Users",
  clients: "Clients",
};

const token = Cookie.get(config.session_cookie_name);

export default (props) => {
  const { page } = props;
  const [
    {
      loggedIn,
      menuExpanded,
      globalFilters,
      globalUpdates,
      user,
      selectedPage: globalSelectedPage,
      globalSearch,
    },
    actions,
  ] = useApp();
  const [results, setResults] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [search, setSearch] = useState(globalSearch);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showProfileModel, setShowProfileModel] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const selectedPage = page ? page : globalSelectedPage;
  const history = useHistory();

  const {
    data: user_data,
    loading: loading_user,
    refetch: refetch_user,
  } = useQuery(GQL.Queries.user_by_token, {
    variables: { token },
  });
  const [doSearch, { data: search_results, loading: loading_search_results }] =
    useLazyQuery(GQL.Queries.devices, {
      onCompleted: (data) => {
        setResults(data.devices);
      },
    });

  useEffect(() => {
    if (debouncedSearch) {
      doSearch({
        variables: {
          limit: 10,
          search,
        },
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setSearch(globalSearch);
  }, [globalSearch]);

  //console.log({ search_results });

  return (
    <Navbar
      style={{
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
    >
      {loggedIn && (
        <Navbar.Body>
          <Navbar.Header
            style={{
              width: "250px",
              padding: "5px",
              //paddingLeft: "85px",
              //paddingRight: "90px",
            }}
          >
            <img src={"/images/mc3-logo.png"} width={48} />
            <span
              style={{
                position: "relative",
                top: "6px",
                //left: "10px",
                fontSize: 20,
              }}
            >
              Conduit Manager
            </span>
          </Navbar.Header>
          {/*
          <Nav style={{ padding: 15, paddingLeft: 25 }}>
            <big>
              <strong>
                {pageTitles[selectedPage] ? pageTitles[selectedPage] : ""}
              </strong>{" "}
              Dashboard
            </big>
            </Nav>
            */}
          <Nav pullRight>
            <Nav.Item
              onClick={() => {
                //history.push("/profile");
                setShowProfileModel(true);
              }}
              icon={
                <Avatar
                  size="xs"
                  circle={true}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                >
                  <Icon icon="user" style={{ color: "#8e9fb1" }} />
                </Avatar>
              }
            >
              <span style={{ color: "#37424E", fontWeight: "bold" }}>
                {" "}
                {user_data &&
                user_data.user_by_token &&
                user_data.user_by_token.user_firstname &&
                user_data.user_by_token.user_lastname
                  ? user_data.user_by_token.user_firstname +
                    " " +
                    user_data.user_by_token.user_lastname
                  : "..."}
              </span>
            </Nav.Item>
            {/*
            <Nav.Item
              icon={<Icon icon="comments" style={{ color: "#37424E" }} />}
            />
            <Nav.Item
              icon={
                <Badge content={"3"}>
                  <Icon icon="globe" style={{ color: "#37424E" }} />
                </Badge>
              }
            />
            */}
            <Nav.Item disabled>
              <span style={{ color: "#37424E" }}>|</span>
            </Nav.Item>
            {/*<Nav.Item icon={<Icon icon="cog" style={{ color: "#37424E" }} />} />*/}
            <Nav.Item
              icon={<Icon icon="power-off" style={{ color: "#37424E" }} />}
              onClick={() => actions.logout()}
            />
          </Nav>
          {/*
          <Nav pullRight>
            <Dropdown
              icon={
                <span style={{ color: "#1F2833", fontWeight: "bold" }}>
                  <Avatar
                    size="xs"
                    circle={true}
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                  >
                    <Icon icon="user" style={{ color: "#2F3B4B" }} />
                  </Avatar>{" "}
                  {user_data &&
                  user_data.user_by_token &&
                  user_data.user_by_token.user_firstname &&
                  user_data.user_by_token.user_lastname
                    ? user_data.user_by_token.user_firstname +
                      " " +
                      user_data.user_by_token.user_lastname
                    : "..."}
                </span>
              }
              trigger={["click", "hover"]}
            >
              <Dropdown.Item
                style={{ color: "#1F2833" }}
                onClick={() => {
                  //actions.setTriggerAccountDetails(true)
                  setShowDetailsModal(true);
                }}
              >
                <Icon icon="cog" /> Profile
              </Dropdown.Item>
            </Dropdown>
          </Nav>
              */}
          <Nav pullRight style={{ padding: 10 }}>
            <InputGroup
              style={{
                border: 0,
                backgroundColor: "rgba(0,0,0,0.3)",
                color: "#53606F",
              }}
            >
              <InputGroup.Addon style={{ backgroundColor: "rgba(0,0,0,0.0)" }}>
                <Icon icon="search" style={{ color: "#8e9fb1" }} />
              </InputGroup.Addon>
              <Input
                placeholder="Search"
                value={search}
                onChange={(value) => {
                  setSearch(value);
                  if (value.length === 0) setResults([]);
                  //else setShowSearchResults(true);
                }}
                style={{
                  borderRadius: "5px",
                  width: "300px",
                  border: 0,
                  backgroundColor: "rgba(0,0,0,0.0)",
                  //color: "#53606F",
                }}
                onClick={() => {
                  if (search.length === 0) {
                    setShowSearchResults(!showSearchResults);
                  } else {
                    setShowSearchResults(true);
                  }
                }}
              />
            </InputGroup>
            {showSearchResults && (
              <Panel
                style={{
                  backgroundColor: "#3A4452",
                  position: "absolute",
                  width: 680,
                  left: -330,
                  //top: -2,
                  padding: 0,
                  zIndex: 999,
                }}
                className="search-panel"
              >
                <Grid fluid>
                  <Row>
                    <Col xs={12} style={{ textAlign: "left" }}>
                      <Icon icon="list" /> Search Results
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Button
                        size="xs"
                        onClick={() => {
                          setSearch("");
                          setResults([]);
                          setShowSearchResults(false);
                        }}
                      >
                        <span>
                          <Icon icon="close" size="xs" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Table
                        data={results}
                        loading={loading_search_results}
                        autoHeight
                        onRowClick={(device) => {
                          history.push("/devices/details/" + device.device_id);
                          setSearch("");
                          setResults([]);
                          setShowSearchResults(false);
                        }}
                      >
                        <Table.Column flexGrow={1}>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.Cell
                            dataKey="device_name"
                            style={{ cursor: "pointer" }}
                          />
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                          <Table.HeaderCell>Serial</Table.HeaderCell>
                          <Table.Cell
                            dataKey="device_serial"
                            style={{ cursor: "pointer" }}
                          />
                        </Table.Column>
                      </Table>
                    </Col>
                  </Row>
                </Grid>
              </Panel>
            )}
            {/*
            <InputGroup
              style={{
                border: 0,
                backgroundColor: "rgba(0,0,0,0.3)",
                color: "#53606F",
              }}
            >
              <InputGroup.Addon style={{ backgroundColor: "rgba(0,0,0,0.0)" }}>
                {search.length > 0 ? (
                  <Icon
                    icon="ban"
                    onClick={() => setSearch("")}
                    style={{ cursor: "pointer", color: "#8e9fb1" }}
                  />
                ) : (
                  <Icon icon="search" style={{ color: "#8e9fb1" }} />
                )}
              </InputGroup.Addon>
              <Input
                placeholder="Search"
                value={search}
                onChange={setSearch}
                style={{
                  borderRadius: "5px",
                  width: "300px",
                  border: 0,
                  backgroundColor: "rgba(0,0,0,0.0)",
                  //color: "#53606F",
                }}
                onClick={() => setShowSearchResults(!showSearchResults)}
              />
            </InputGroup>
              */}
          </Nav>
        </Navbar.Body>
      )}
      <Modal
        show={showProfileModel}
        size="md"
        backdrop="false"
        dialogClassName="login"
      >
        <Modal.Header onHide={() => setShowProfileModel(false)}>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Profile />
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};
